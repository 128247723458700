<template>
  <v-container id="user_info" fluid tag="section">
    <v-card>
      <v-form ref="form">
        <v-container class="py-2">
          <v-row>
            <v-col cols="6" md="6">
              <v-select
                :items="users"
                label="Select User"
                item-text="first_name"
                item-value="_id"
                v-model="userId"
                :rules="selectRule"
                :disabled="onlyView || editUserInfo"
                @change="getUserId($event)"
              >
                <template slot="selection" slot-scope="data">
                  <!-- HTML that describe how select should render selected items -->
                  {{ data.item.first_name }} {{ data.item.last_name }}
                </template>
                <template slot="item" slot-scope="data">
                  <!-- HTML that describe how select should render items when the select is open -->
                  {{ data.item.first_name }} {{ data.item.last_name }}
                </template>
              </v-select>
            </v-col>
            <v-col cols="6" md="6">
              <v-text-field
                label="Address"
                class="purple-input"
                v-model="address"
                :rules="inputRule"
                :disabled="onlyView"
              />
            </v-col>
          </v-row>
          <!-- <div v-if="userType == 'Customer'"> -->
          <v-row>
            <v-col cols="6" md="6">
              <v-text-field
                label="Street"
                class="purple-input"
                v-model="street"
                :rules="inputRule"
                :disabled="onlyView"
              />
            </v-col>
            <v-col cols="6" md="6">
              <v-text-field
                label="City"
                class="purple-input"
                v-model="city"
                :rules="inputRule"
                :disabled="onlyView"
              />
            </v-col>
            <v-col cols="6" md="6">
              <v-text-field
                label="Post Code"
                class="purple-input"
                v-model="postcode"
                :rules="inputRule"
                :disabled="onlyView"
              />
            </v-col>
            <v-col cols="6" md="6">
              <v-text-field
                label="Country"
                class="purple-input"
                v-model="country"
                :rules="inputRule"
                :disabled="onlyView"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6" md="6">
              <v-radio-group
                label="Select Delivery Type: "
                v-model="delivery_type"
                :disabled="onlyView"
                row
              >
                <v-radio label="Home" value="Home"></v-radio>
                <v-radio label="Office" value="Office"></v-radio>
                <v-radio label="Hotel" value="Hotel"></v-radio>
              </v-radio-group>
            </v-col>
            <v-col cols="6" md="6">
              <v-text-field
                label="Time"
                class="purple-input"
                v-model="time"
                :rules="inputRule"
                :disabled="onlyView"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" class="text-right">
              <v-btn
                type="button"
                color="primary"
                class="mr-0"
                @click="submit"
                :loading="loading"
              >
                {{
                  onlyView == true
                    ? "Back"
                    : editUserInfo == true
                    ? "Update"
                    : "Add"
                }}
              </v-btn>
            </v-col>
          </v-row>
          <!-- </div> -->
        </v-container>
      </v-form>
    </v-card>
  </v-container>
</template>

<script>
export default {
  props: ["id", "action"],
  data() {
    return {
      userId: "",
      editUserInfo: null,
      onlyView: false,
      users: [],
      userId: null,
      selectRule: [(v) => !!v || "Select any store"],
      inputRule: [(v) => !!v || "Field is required"],
      address: "",
      street: "",
      city: "",
      postcode: "",
      country: "",
      delivery_type: "",
      time: "",
      loading: false,
      showError: false,
      showErrorData: null,
    };
  },
  methods: {
    getUersList() {
      axios
        .get(process.env.VUE_APP_API_BASE_URL + "userInfo/getCustomer")
        .then((response) => {
          if (response.status == 200) {
            this.users = response.data.data;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getUserInfoData(id) {
      axios
        .get(process.env.VUE_APP_API_BASE_URL + "userInfo/edit/" + id)
        .then((response) => {
          if (response.status == 200) {
            this.userId = response.data.userInfo.user_id;
            this.address = response.data.userInfo.address;
            this.street = response.data.userInfo.street;
            this.city = response.data.userInfo.city;
            this.postcode = response.data.userInfo.postcode;
            this.country = response.data.userInfo.country;
            this.delivery_type = response.data.userInfo.delivery_type;
            this.time = response.data.userInfo.time;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getUserId(userId) {
      this.users.forEach((user) => {
        if (userId == user._id) {
          this.userId = user._id;
        }
      });
    },
    submit() {
      this.showError = false;
      if (this.$refs.form.validate()) {
        this.loading = true;
        let formData = new FormData();
        formData.append("id", this.id);
        formData.append("user_id", this.userId);
        formData.append("address", this.address);
        formData.append("street", this.street);
        formData.append("city", this.city);
        formData.append("postcode", this.postcode);
        formData.append("country", this.country);
        formData.append("delivery_type", this.delivery_type);
        formData.append("time", this.time);
        if (this.onlyView) {
          this.$router.push({ name: "User Info" });
        } else if (this.editUserInfo == true) {
          axios
            .post(
              process.env.VUE_APP_API_BASE_URL + "userInfo/update",
              formData
            )
            .then((response) => {
              if (response.status == 200) {
                this.loading = false;
                this.$router.push({
                  name: "User Info",
                  params: { edit: true },
                });
              }
            })
            .catch((error) => {
              this.loading = false;
              this.showError = true;
              this.showErrorData = error.response.data.name[0];
            });
        } else {
          axios
            .post(
              process.env.VUE_APP_API_BASE_URL + "userInfo/create",
              formData
            )
            .then((response) => {
              if (response.status == 200) {
                this.loading = false;
                this.$router.push({ name: "User Info", params: { add: true } });
              }
            })
            .catch((error) => {
              this.loading = false;
              this.showError = true;
              this.showErrorData = error.response.data.name[0];
            });
        }
      }
    },
  },
  mounted() {
    this.getUersList();
    if (this.id != undefined) {
      this.getUserInfoData(this.id);
      this.editUserInfo = true;
    } else {
      this.editUserInfo = false;
    }
    if (this.action == "view") {
      this.onlyView = true;
    }
  },
};
</script>